@import '~antd/dist/antd.css';
@import '@ant-design/pro-form/dist/form.css';

@font-face {
  font-family: 'Poppins Regular';
  src: url('assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins Medium';
  src: url('assets/fonts/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppins SemiBold';
  src: url('assets/fonts/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins Bold';
  src: url('assets/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'AvenirNextCyr Demi';
  src: url('assets/fonts/AvenirNextCyr-Demi.ttf');
}

@font-face {
  font-family: 'AvenirNextCyr Regular';
  src: url('assets/fonts/AvenirNextCyr-Regular.ttf');
}

@font-face {
  font-family: 'BebasNeue Light';
  src: url('assets/fonts/BebasNeue-Light.otf');
}

@font-face {
  font-family: 'BebasNeue Regular';
  src: url('assets/fonts/BebasNeue-Regular.ttf');
}

@font-face {
  font-family: 'BebasNeue Bold';
  src: url('assets/fonts/BebasNeue-Bold.ttf');
}



* {
  box-sizing: border-box;
}

#root,
body {
  resize: both;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: 'Poppins Regular';
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
}

button:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  background: rgba(0, 0, 0, 0.12) !important;
  border: none !important;
}

input {
  outline: none;
}

input:disabled {
  color: rgba(0, 0, 0, 0.26);
}

img {
  max-width: 100%;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border: none;
}

::-webkit-scrollbar-thumb {
  background: #d6dee1;
  border-radius: 2px;
  height: 30px;
}
@media (max-width: 450px) {
  .ant-picker-datetime-panel {
    flex-direction: column;
  }
}
